.talkContainer {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.176);
    z-index: 1111;
    top: 0;
    position: fixed;
    overflow-y: scroll;
    align-items: center;
    justify-content: center;
    display: none;
}

.talkContainer-bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9998;
    overflow-y: auto;
}

.talkContainer .talkCard {
    display: flex;
    flex-direction: column;
    width: 55%;
    min-height: 85%;
    margin: 30% 0 1% 0;
    align-items: center;
    position: relative;
    border-radius: 5px;
    background-color: #fbf6e4;
    top: 80px;
    z-index: 9999;
}
.talkContainer .talkCard .closeBtn {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2%;
    right: 3%;
    cursor: pointer;
}
.talkContainer .talkCard .closeBtn i {
    font-size: 1.5rem;
    color: #000;
}
.talkHead{
    background-color: #111;
    color: #fff;
}
.talkContainer .talkCard .talkHead {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3% 3%;
    width: 50%;
    color: #fff;
    font-size: 1.3rem;
    margin: 3% 0;
    height: 20px;
    border: 1px solid #fac710;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px rgba(250, 199, 16, 0.75) inset;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(250, 199, 16, 0.75) inset;
    -moz-box-shadow: 0px 0px 4px 0px rgba(250, 199, 16, 0.75) inset;
}
.talkContainer .talkCard .talkText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    color: #000;
    font-size: 1.1rem;
    gap: 4px;
}
.talkContainer .talkCard .talkText p {
    color: #000;
    font-size: 1.1rem;
}
.talkContainer .talkCard .txthead {
    display: flex;
    width: 90%;
    gap: 5px;
    margin: 5% 0;
    margin-bottom: 2%;
    color: #000;
    font-size: 1.1rem;
    font-weight: 500;
}
.talkContainer .talkCard .txthead span {
    color: #000;
    font-size: 1.1rem;
    border-bottom: 4px solid #fac710;
}
.talkContainer .talkCard .mediaCards {
    display: flex;
    flex-direction: row;
    gap: 20%;
    width: 80%;
    justify-content: center;
    align-items: center;
}
.talkContainer .talkCard .mediaCards .mediaCardsRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
}
.talkContainer .talkCard .mediaCards .mediaCard {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #000;
}
.talkContainer .talkCard .mediaCards .mediaCard img {
    display: flex;
    width: 60%;
    height: 60%;
    border-radius: 100%;
}
.talkContainer .talkCard .mediaCards .mediaTxt {
    color: #000;
    font-size: 1rem;
}
.talkContainer .talkCard .talkInputs {
    display: flex;
    flex-direction: column;
    width: 90%;
    font-family: var(--font-family);
}
.talkContainer .talkCard .talkInputs input, .talkContainer .talkCard .talkInputs textarea, .talkContainer .talkCard .talkInputs select {
    color: black;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* width: 100%; */
    margin: 1% 0;
    text-align: center;
    font-family: var(--font-family);
}
.talkContainer .talkCard .talkBtn {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.talkContainer .talkCard .talkBtn .improve-erep {
    width: 52%;
    align-self: center;
    margin-top: 1rem;
    padding: 5px 5px;
    margin-bottom: 1rem;
    border: none;
    background-color: black;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 3.5rem;
    border-radius: 2rem;
}

.talkContainer .talkCard .talkBtn .improve-erep:hover {
    background-color: #fac710;
    color: #111;
    transform: scale(.9);
    transition: .8s;
}  
.show {
    display: flex;
    justify-content: center;
    /* background: var(--alert-info); */
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    /* width: fit-content; */
}
.hide {
    display: none;
}
@media only screen and (max-width: 430px) {
    .talkCard {
        margin-top: 15% !important;
        width: 90% !important;
   }
    .talkCard .closeBtn {
        top: 1% !important;
        right: 2% !important;
   }
    .talkCard .talkHead {
        padding: 5% 3% !important;
        width: 70% !important;
        font-size: 1.2rem !important;
        height: 20px;
   }
    .talkCard .talkText {
        width: 80% !important;
        color: #000;
        text-align: center;
   }
    .talkCard .mediaCards {
        gap: 15% !important;
        width: 70% !important;
        margin: 2% !important;
   }
    .talkCard .mediaCards .mediaCardsRow {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30%;
   }
    .talkCard .mediaCards .mediaCard {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: #000;
   }
    .talkCard .mediaCards .mediaCard img {
        display: flex;
        width: 60%;
        height: 60%;
        border-radius: 100%;
   }
    .talkCard .mediaCards .mediaTxt {
        text-align: center;
        color: #000;
        font-size: 0.8rem !important;
   }
}
