/* .content-ideas-form {
    background-color: #FBF6E4;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 80%;
    width: 387px;
    text-align: center;
    position: relative;
} */

#notify{
    width: 350px !important;
    z-index: 60;
}


.hide{
    display: none;
}

.additional-section .close-button1 {
    position: relative;
    top: -145px;
    left: 340px;
    width: fit-content;
    cursor: pointer;
}