nav {
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;
  position: fixed;
  background-color: var(--white-background);
  top: 0;
  left: 0;
  width: 100%; 
  z-index: 9999;
}
nav .left {
  max-width: calc(380px + 1vw);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 78px;
  height: 70px;
  border-radius: 50%;
  border: 3px solid var(--org-primary-color);
  color: var(--navbar-color);
  cursor: pointer;

  font-family: Lato;
  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: center;

}

nav .right {
  margin-right: 100px;
}

nav .left img {
  max-width: calc(50px + 1vw);
  padding-right: 20px;
}


nav .desktop-resp {
  display: inline-block;
}

nav .mobile-resp {
  font-weight: 900;
  display: none;
}

nav .left h2 {
  font-weight: 300;
  font-size: calc(14px + 1vw);
}

ul {
  list-style: none;
  padding: 0;
}

li {
  display: inline-block;
  margin-right: 8px;
  color: aliceblue;
}


/* Texts.css */
.text-container, .ga-text-container {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 228px;
  height: 53px;
  top: 33px;
  left: 1102px;
  border-radius: 42.06px;
  border: 5px;
  background-color: var(--navbar-color);
  color: var(--white-background);
  transition: 1s;
}

.ga-text-container {
  background-color: var(--org-primary-color);
  color: var(--navbar-color);
  border: 2px solid var(--navbar-color);  
}

.text-container:hover{
  background-color: var(--org-primary-color);
  color: var(--navbar-color);
  transform: scale(.9);
  transition: .8s;
}

.ga-text-container:hover {
  transform: scale(.9);
  transition: .8s;
}


.text {
  height: 29px;
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
  letter-spacing: 0.01em;
  text-align: center;
}

@media screen and (max-width: 480px) {
  nav {
    padding: 0 20px;
    justify-content: space-between;
  }

   nav .desktop-resp {
    display: none;
  }

  nav .mobile-resp {
    padding-right: 2rem;
    font-weight: 900;
    display: inline-block;
    white-space: nowrap;
  }

  nav .left {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }

  nav .right {
    display: flex;
    top: unset;
    justify-content: right;
    align-items: center;
   
  }

  nav .left img {
    padding-right: 10px;
  }

  .text-container, .ga-text-container {
    left: unset;
    right: unset;
    top: unset;
    z-index: 40;
    width: 120px;
    height: 40px;
  }

  .text {
    font-size: 16px;
    font-weight: 300;
  }
}
