/*------------------------ footer styles -------*/
.footer-content-wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
  /* overflow: hidden; */
}

.footer-content-wrapper .footer-container {
  background-color: var(--navbar-color);
  color: #fff;
  border-top-right-radius: 150px;
  z-index: 33;
  padding: 1.5rem 3rem;
}

.footer-content-wrapper .footer-logo {
  width: 100%;
  /* max-width: 405px; */
  display: flex;
  align-items: center;
  flex-direction: row;
  /* justify-content: space-between; */
  /* padding: 0 6rem; */
  padding-top: 20px;
  gap: 30px;
}

.ft-logo {
  /* max-width: calc(380px + 1vw); */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 70px;
  border-radius: 50%;
  border: 3px solid var(--org-primary-color);
  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: center;
  background-color: transparent;
  color: var(--white-background);
  cursor: pointer;
}


.footer-content-wrapper .footer-logo h2 {
  font-weight: 300;
  font-size: 40px;
  /* right: 58rem; */
  position: relative;

  /* line-height: 40px;
  letter-spacing: -0.01em;
  text-align: left; */

}

.footer-container .footer-link-bars {
  /* width: 80%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 6rem; */
}

.footer-container .footer-bar-left {
  font-size: 20px;
  line-height: 32px;
  max-width: 500px;

  height: 255px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: left;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.footer-bar-left .address {
  font-size: 16px;
  width: 429px;
  height: 27px;
  line-height: 25px;
}

.footer-link-bars .footer-bar {
  display: flex;
  justify-content: space-evenly;
  width: 65%;
  height: 100%;
  position: relative;
  top: -60px;
}

.footer-bar .footer-links h2 {
  font-size: 30px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0.02em;
  text-align: left;
}

.footer-container .footer-bar ul {
  display: flex;
  flex-direction: column;

}

.footer-container .footer-bar ul li {
  font-size: 20px;
  line-height: 32px;
  margin: 5px 0;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: left;
  cursor: pointer;
  transition: .5s;
}

.footer-container .footer-bar ul li:hover {
  transform: scale(1.2);
  color: var(--org-primary-color);
  transition: .5s;
}

.footer-container .footer-email-subz {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-top: 2.6px solid var(--white-background);
  border-bottom: 2.6px solid var(--white-background); */
  padding: 1rem 6rem;
  font-size: 16px;
  text-transform: uppercase;
  border: 2.6px solid #FFFFFF

}

.footer-container .footer-email-subz .foot-text {
  width: 100%;
  max-width: 300px;
  width: 195px;

  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-align: left;
}

.footer-container .footer-email-subz .footer-nls {
  width: 100%;
  padding: 10px 0;
}

.footer-container .footer-email-subz .form-group {
  display: flex;
}

.footer-container .footer-email-subz input {
  color: #000;
  width: 70%;
  height: 49px;
  border: 1px solid #fff;
  background: #fac710;
  font-size: 16px;
  text-align: center;
  outline: 2px solid var(--org-primary-color);
}

.footer-container .footer-email-subz input[type="email"]:focus {
  outline: 2px solid var(--org-primary-color);
}

.footer-container .footer-email-subz input::placeholder {
  text-align: center; 
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.02em;
  
}

.footer-container .footer-email-subz .btn {
  width: 25%;
  font-size: 16px;
  height: 53px;
  border: none;
  color: #000;
  background: #ffff;
  font-weight: 500;
  border: 1px solid #fac710;
  cursor: pointer;
  transition: .5s;
}

.footer-container .footer-email-subz .btn:hover {
  background: var(--navbar-color);
  color: var(--org-primary-color);
}

.footer-container .footer-email-subz .btn:active {
  transform: scale(.95);
  transition: .5s;
}

.confirm-subcription p {
  text-transform: initial;
  text-align: center;
}

.footer-container .footer-email-subz span {
  color: red;
  display: none;
}

.footer-container .footer-social-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 85%; */
  height: 234px;
  border: 2.6px;

}

.footer-social-links .solution .xds-team {
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  gap: 10px;
}

.footer-social-links .solution .xds-team .xds-logo {
  width: 120px;
  height: 98px;
  border: 3px solid var(--white-background);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--white-background);
}

.footer-social-links .solution .xds-team .xds-logo div {
  width: 76px;
  height: 78px;
  border-radius: 50%;
  border: 3px solid var(--org-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-size: 28px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
}

.footer-container .footer-social-links .social-link-left {
  margin-left: 7px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.footer-container .footer-social-links .social-link-left p {
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;

}

.footer-container .footer-social-links .social-link-left img {
  width:"2rem"; 
  cursor:"pointer";
}

.footer-container .footer-social-links .copyright-field {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
}

.footer-container .footer-social-links .copyright-field a {
  color: var(--white-background);
  
}

.footer-container .footer-social-links .copyright-field img {
  width: 40px;
  height: 100%;
}

/* MOBILE RESPONSIVE */

@media only screen and (max-width: 920px){
.footer-container .footer-social-links .copyright-field {
  display: flex;
  /* display: flex; */
  align-items: center;
  font-size: 20px;
  font-weight: 400;
}

.ft-logo {
  width: 50px;
  height: 50px;
  font-size: 20px;
  font-weight: 400;
}

.footer-container .footer-email-subz {
  padding: 0rem;
  margin: 40px 0;
  flex-direction: column;
  padding: 0 10px;
  width: 102%;
  padding-bottom: 10px;
}

.footer-container .footer-email-subz .foot-text {
  width: unset;
  font-weight: 400;
}
.footer-container .footer-email-subz input::placeholder {
  font-size: 16px;
}

.footer-container .footer-email-subz span {
  font-size: 15px;
  text-transform: initial;
  margin-top: 10px;
}

.footer-content-wrapper .footer-container {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.footer-container .footer-link-bars {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-bar {
  margin-top: 2rem;
  top: unset !important;
  width: unset !important;
  flex-direction: column;
}

.footer-bar .footer-links h2 {
  margin-bottom: unset;
}

li.business-readiness-check-mobile {
  padding-bottom: 3rem;
}

.footer-bar-left .address {
  width: unset;
}

.footer-container .footer-social-links {
  flex-direction: column;
  width: 105%;
}

.footer-social-links .solution .xds-team {
  font-size: 16px;
  font-weight: 400;
}

.footer-social-links .solution .xds-team .xds-logo {
  width: 50px;
  height: 55px;
}

.footer-social-links .solution .xds-team .xds-logo div {
  width: 48px;
  height: 50px;
  font-size: 16px;
  font-weight: 400  ;
}


.footer-container .footer-social-links .social-link-left p {
  font-size: 20px;
}

.footer-container .footer-social-links .copyright-field img {
  height: unset;
  font-size: 20px;
}
}


