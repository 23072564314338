@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
  font-family: Lato;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  
  --org-primary-color: #fac710;
  --text-color: #6d6d6d;
  --navbar-color: #111111;
  --social-icons-background-color: #e5e1e1;
  --copyright-section-color: #e5e1e1;
  --Fun-facts-section-color: #fbf6e4;
  --white-background: #fff;
  --alert-sucess: #cff6e4;
  --alert-info: #fef2d0;
  --alert-danger: #f7dfe1;
  --shadow: 0 8px 17px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  --font-family: Lato;
  --header-fontsize: 3.75rem;
  --header-font: Bold;
  --min-header-fontsize: 2.5rem;
  --min-header-font: Bold;
  --max-paragraph-fontsize: 2.19rem;
  --max-paragraph-font: Regular;
  --paragraph-fontsize: 1.56rem;
  --paragraph-font: Regular;
  --nav-link-text-fontsize: 1.56rem;
  --nav-link-text-font: Bold;
}

a {
  text-decoration: none;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding-top: 95px;
}

.main-wrapper {
  /* background-image: url("./assets/images/imageBg1.svg"); */
  background-position: right center;
  background-size: 53% 95vh;
  background-repeat: no-repeat;
  width: 100%;
  margin: 0 auto;
  height: 95vh;
  top: 0;
}

@media (max-width: 920px) {
  .main-wrapper {
    background-size: contain;
  }
 }

 @media only screen and (max-width: 920px){
  .main-wrapper{
    background-position: right -3px bottom 32rem;
    background-size: 16rem;
    height: 101vh;
  }
 }


