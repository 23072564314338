.xawow-wrapper {
  top: 0px;
  z-index: 9999;
  height: 100%;
  width: 100%;

  position: fixed;
  display: block;
  margin: 0px;
  padding: 0px;
  overflow: visible;
  transform-style: preserve-3d;
  /* background: var(--navbar-color); */
  backface-visibility: hidden;
  pointer-events: none;
  left: 0px;

}

.xw-hide {
  display: none;
}

.xw-closeBtn {
  justify-content: end;
  display: flex;
  top: 50px;
  position: relative;
  /* font-size: 20px; */
}

.xw-closeBtn button {
  cursor: pointer;
  transition: .9s;
  border: none;
  background: none;
  font-size: 20px;
}

.xw-closeBtn button:active {
  transform: scale(.85);
  transition: .8s;
}

.bg-shadow {
  z-index: 99998;
  opacity: 0.4;
  visibility: visible;
  pointer-events: all;
  cursor: pointer;
  position: absolute;
  width: 101vw;
  height: 101vh;
  background: var(--navbar-color);
}

.xawow-container {
  width: 91%;
  height: 804px;
  z-index: 99999;
  visibility: visible;
  position: absolute;
  inset: 50vh auto auto 50%;
      top: 50vh;
      left: 50%;
  transform: translate(-50%,-50%);
  left: 50%;
  top: 50%;
  opacity: 1;
  pointer-events: auto;
  max-height: 95%;
  max-width: 95%;
  background: #FBF6E4;
  border-radius: 10px;
  box-shadow: 0px 0px 9px 2px #0000000D;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  box-sizing: border-box;
}

.xw-main {
  overflow-y: auto;
  width: 100%;
  padding: 0 50px;
  box-sizing: border-box;
  display: grid;

}

.xw-heading, .xw-desc {
  justify-self: center;
}

.xw-heading {
  width: 55%;
  border-radius: 10px;
  border: 3px solid var(--org-primary-color);
  box-shadow: 0px 0px 9px 2px #0000000D;
  display: flex;
  justify-content: center;
  align-items: center;
  
  font-size: 60px;
  font-weight: 400;
  line-height: 73px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--white-background);
  background: var(--navbar-color);
}

.xw-desc {
  font-size: 28px;
  font-weight: 400;
  line-height: 51px;
  letter-spacing: -0.01em;
  text-align: center;

}

.xw-desc {
  width: 800px;
}

.xw-desc span {
  font-weight: 800;
}

.xw-bk-container {
  display: grid;
}

.xw-booking {
  width: 100%;
  margin-top: 30px;
  display: flex;
  gap: 40px;
}

.xw-calender {
  width: 60%;
  background: var(--navbar-color);
  /* height: 864px; */
  border-radius: 9px;
  display: flex;
  padding: 40px;
  box-sizing: border-box;

}

.xw-time {
  width: 37%;
  /* height: 864px; */
  border-radius: 8px;
  box-shadow: 0px 30px 84px 0px #130A2E14;
  background: var(--navbar-color);
  /* justify-content: center; */
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  padding-top: 80px;
  /* gap: 150px; */
  color: var(--white-background);
  flex-direction: column;
  max-height: 663px;
}

.xw-time ul {
  gap: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  
}

.xw-time ul li {
  margin: 0;
  align-items: center;
  display: flex;
  justify-content: center;
}

.time-desc {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  gap: 10px;
  align-items: center;
}

.time-desc span {
  font-size: 14px;
}

.av-day {
  background: var(--white-background) !important;
  border-radius: 50%;
  height: 80px !important;
  cursor: pointer;
  transition: 1s;
}

.av-day:disabled {
  background: unset !important;
  cursor: unset;
}

.av-day:disabled:active {
  transform: unset;
  transition: unset;
}

.av-day:active {
  transform: scale(.6);
  transition: 1s;
  background: var(--org-primary-color) !important;
}

.av-day:hover {
  background: var(--org-primary-color);
}

.unav-time {
  background: none;
  color: var(--text-color);
}

.av-time {
  background: var(--white-background);
  cursor: pointer;
}

.av-time, .unav-time {
  width: 295px;
  height: 32px;
  padding: 4px, 16px, 4px, 16px;
  border-radius: 6px;
  gap: 8px;
  display: flex;
  align-items: center;
  padding: 0 40px ;
 
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  justify-content: center;
  transition: .8s;
}

.av-time:hover {
  background: var(--org-primary-color);
}

.av-time:active {
  transform: scale(.8);
  transition: .6s;
}

.xw-signup-btn, .xw-form-btn, .xw-form form input[type='submit'] {
  width: 658px;
  height: 94px;
  border-radius: 42.06px;
  border: 5px;
  background: var(--navbar-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  
  font-family: var(--font-family);
  font-size: 35px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0.01em;
  text-align: center;
  color: var(--white-background);
  margin: 50px 0;
  justify-self: center;
  cursor: pointer;
  transition: .9s;
}

.xw-signup-btn:hover, .xw-form-btn:hover {
  background: var(--org-primary-color);
  color: var(--navbar-color);
}

.xw-signup-btn:active, .xw-form-btn:active {
  transform: scale(.8);
  transition: .9s;
}

.xw-form form {
  background: var(--navbar-color);
  /* height: 864px; */
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  padding: 40px 95px;
  box-sizing: border-box;
  width: 90%;
  margin: 0 auto;
  gap: 20px;
}

.xw-form form input, .xw-form form select {
  font-family: var(--font-family) !important;
  height: 66px;
  padding: 4px 16px;
  border-radius: 6px;
  background: var(--white-background);
  border: none;

  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;  
  color: var(--navbar-color);
}

.xw-form form input::placeholder {  

  font-family: var(--font-family);
}

.xw-form form select option {
  font-family: var(--font-family);
  /* background: var(--navbar-color); */
  cursor: pointer;
  /* color: var(--white-background); */
}

.xw-form form select option:active {
  background: var(--navbar-color) !important;
}

.xw-form-btns {
  display: flex;
  gap: 40px;
  justify-content: center;
}

.xw-form-btn, .xw-form form input[type='submit'] {
  /* width: 40%; */
  height: 78px;
  font-size: 25px;
  transition: .9s;  
  font-family: var(--font-family);
}

.xw-form form input[type='submit'] {
  position: relative;
  /* top: 224px;
  left: 500px; */
  width: inherit;
  margin: auto;
  background: var(--white-background);
  color: var(--navbar-color);
  margin-top: 25px;

}

.xw-form form input[type='submit']:hover {
  transform: scale(.8);
  transition: .9s;
}

.xw-confirm {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  background: var(--white-background);
  font-family: var(--font-family);
  font-size: 25px;
  border-radius: 10px;
  width: 320px;
  color: var(--navbar-color);
  height: 50px;
  position: absolute;
  right: 40px;
  top: 100px;
  padding-left: 12px;
  transition : all .3s;
  -wekit-transition : all .3s;
  -moz-transition : all .3s;
}



/* ------------------------------- REACT CALENDER SECTION ---------------------------------------- */

.react-calendar button, .react-calendar__month-view__weekdays {
  font-family: var(--font-family);
  color: var(--text-color);
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  justify-content: center;
}

.react-calendar__navigation button {
  border: none;
  background: initial;
}

.react-calendar__navigation .react-calendar__navigation__label {
  width: 363.78px;
  height: 56.5px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.3799999952316284px;
  text-align: left;
  flex-grow: 0 !important;
  color: var(--copyright-section-color);   
  left: -85px;
  position: relative;
  
}

.react-calendar__navigation__label .react-calendar__navigation__label__labelText {
cursor: pointer;
}

.react-calendar__navigation .react-calendar__navigation__prev-button,
.react-calendar__navigation .react-calendar__navigation__next-button {
  color: var(--org-primary-color);
  width: 20px;
  font-size: 40px;
  position: relative;
  left: 60px;
  font-weight: 300;
  cursor: pointer;
}

.react-calendar__navigation .react-calendar__navigation__prev-button {
  left: 395px;
}

.react-calendar__navigation .react-calendar__navigation__prev2-button,
.react-calendar__navigation .react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday {
  width: 75.67px;
  height: 37.67px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;


}
.react-calendar__month-view__days button {
  width: 135.12px;
  height: 90.13px;
  font-size: 16px;
  font-weight: 800;
  line-height: 21px;
  letter-spacing: -0.3199999928474426px;
  text-align: center;
  background: initial;
  border: none;

} 

.react-calendar__month-view__days > .active-date {
  background: var(--org-primary-color) !important;
  /* border-radius: 50%;
  height: 80px !important;
  cursor: pointer; */
}

.react-calendar__month-view .react-calendar__tile--now {
  /* background: var(--org-primary-color) !important; */
  border-radius: 24%;
  height: 80px !important;
  border-bottom: 3px solid var(--white-background);

}



/* ---------------------------------------MOBILE RESPONSIVENESS-------------------------------------------- */

@media screen and (max-width: 480px) {
  .xawow-wrapper {
    width: 100vw;
  }
  .xawow-container {
    height: unset;
    width: 97%;
  }

  .xw-main {
    padding: 0 10px;
    /* width: 100vw; */
    justify-content: center;
    top: 20px;
    position: relative;
  }

  .xw-closeBtn {
    top: 16px;
  }

  .xw-heading {
    width: unset;
    font-size: 35px;
    line-height: 60px;
    padding: 0 10px;
  }

  .xw-desc {
    width: 85%;
    font-size: 20px;
    font-weight: 400;
    line-height: 33px;
    padding: 0 20px;
  }

  .xw-bk-container {
    width: 90vw;
    justify-content: center;
  }

  .xw-booking {
    flex-direction: column;
    width: 95vw;
    justify-content: center;
    align-items: center;
  }

  .xw-calender, .xw-time {
    width: 95%;
    padding: 20px 10px;
  }

  .xw-calender .react-calendar {
    width: inherit;
  }

  .react-calendar__navigation .react-calendar__navigation__label {
    width: unset;
  }

  .react-calendar__navigation .react-calendar__navigation__next-button {
    left: 70px;
  }

  .react-calendar__navigation .react-calendar__navigation__prev-button {
    left: 135px;
  }

  .react-calendar__month-view__days button, .react-calendar__month-view .react-calendar__tile--now {
    height: 45px !important;
    margin-top: 5px;
  }

  .xw-time {
    align-items: center;
    /* gap: 60px; */
    height: 380px;
  }

  .xw-form form{
    padding: 40px 15px;
  }

  .xw-form form input, .xw-form form select {
    height: 40px;
    font-size: 16px;
  }

  .xw-form form input[type="submit"] {
    width: 100%;
  }

  .xw-form-btns {
    width: 90vw;
    justify-self: center;
  }

  .xw-form-btn, .xw-form form input[type="submit"] {
    height: 60px;
    font-size: 18px;
  }

  .xw-confirm {
    top: 165px;
    position: fixed;
    right: unset;
  }

}
