.pricing-container {
  background-color: #fbf6e4;
  border-top-right-radius: 400px;
  /* margin-right: 80px; */
  position: relative;
  padding: 3rem 1rem;
}

.pricing-section {
  width: 96%;
  margin: 0 auto;
  position: relative;
  padding: 40px 0 40px 0;
  font-family: 'Lato';
}


.header-bold {
  font-weight: bold;
  margin-top: 2rem;
  font-size: 42px;
  position: relative;
  top: -2rem;
}

.slim-text.line {
  border-bottom: 11px solid #fac710;
  margin-top: 5px;
  padding-bottom: 12px;
}

.slim-text {
  font-size: 45px;
  position: relative;
  top: -57px;

  font-weight: 300;
  color: #111111;
}

.second-text {
  margin-top: 1em;
}

.sub-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  width: 858px;
}

.prices-container {
  display: flex;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e6e9f5;
  width: 100%;
  max-width: 100%;
}

/* .pricing-heading,
.prices-container {
  margin-left: 5em;
} */
.price-topic {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e6e9f5;
  width: 31%;
}

.price-topic2 {
  display: flex;
  flex-direction: column;
  transition: transform 0.2s, border-color 0.2s;
  border-right: 1px solid #e6e9f5;
  width: 23%;
}

.price-topic2:hover {
  /* border-color: #fac710; */
  transform: scale(1.01);
  border: 3.5px solid var(--navbar-color);
  cursor: pointer;
}

.price-topic3 {
  display: flex;
  flex-direction: column;
  transition: transform 0.2s, border-color 0.2s;
  border-right: 1px solid #e6e9f5;
  width: 23%;
}

.price-topic3:hover {
  transform: scale(1.01);
  border: 3.5px solid var(--navbar-color);
  cursor: pointer;
}

.price-topic4 {
  display: flex;
  flex-direction: column;
  transition: transform 0.2s, border-color 0.2s;
  border-right: 1px solid #e6e9f5;
  width: 23%;
}

.price-topic4:hover {
  transform: scale(1.01);
  border: 3.5px solid var(--navbar-color);
  cursor: pointer;
}

.price-info {
  border-bottom: 1px solid #e6e9f5;
  padding: 10px 22px;
  /* width: 230px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.price-info.head {
  height: 157.4px;
}

.header-price {
  color: #252430;

  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.forty-off {
  white-space: nowrap;
  border-radius: 20px;
  border: 0.5px solid #fac710;
  background-color: #fac710;
  padding: 5px 15px;
  position: absolute;
  left: 198px;
  /* width: 8rem; */
  text-align: center;
  font-weight: 500;
}

.price-details {
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  padding:1rem
}

.repair {
  /* line-height: 12px; */
  text-align: center;
  color: #252430;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
}

div.choose-plan-btn {
  text-align: center;
  border-radius: 4px;
  background: #252430;
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  height: 58px;
  /* width: 170px; */
  color: var(--white-background
  );
}

div.choose-plan-btn:hover,
div.choose-plan-btn:focus {
  background: #fac710;
  color: var(--navbar-color);
}

.choose-plan-btn p {
  /* color: #fff; */
  cursor: pointer;
}

span.start {
  margin-top: -2rem;
  text-align: center;
  color: #252430;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
}

span.weeks {
  color: #858ba0;

  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}
p.weeks {
  color: #858ba0;

  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  text-align: center;
}

.except {
}

.except-2 {
}

.except-2 p {
  text-align: center;
}

div.price-carrousel {
  display: flex;
  /* width: 1099px; */
  width: 90%;
  margin-top: 4rem;
  flex-direction: column;
}

.price-data {
  font-size: 18px;
  font-style: normal;
  font-weight: 550;
  text-align: left;
  color: #252430;
}

.price-data.centered {
  text-align: center;
}
.price-data.shift {
}
.price-data.shift1 {
}
.price-data.shift2 {
}
.price-data.shift3 {
}
.price-data.shift4 {
}

.price-info {
  height: 80px;
}

.btn-switch {
  position: absolute;
  left: 76%;
  top: 110px;
}

.switch-1 {
  cursor: pointer;
  position: absolute;
  left: -40px;
  height: 30px;
  width: 30px;
  border: 1px SOLID #858ba0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.switch-1:hover,
.switch-2:hover {
  transform: scale(1.1);
}

.switch-1 p {
  color: #858ba0;
}

.switch-1.active {
  border: 1px SOLID #000;
}

.switch-1.active p {
  color: #000;
}
.switch-2.active {
  border: 1px SOLID #858ba0;
}

.switch-2.active p {
  color: #858ba0;
}
.switch-2 {
  position: absolute;
  height: 30px;
  width: 30px;
  border: 1px SOLID BLACK;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
}
.gagile-description-para p {
  width: 403px;
  letter-spacing: -0.87px;
  color: #111;
  text-align: left;
  font-weight: 300;
  font-size: 24px;
  margin-bottom: 2em;
  margin-left: 2em;
}

.image-check {
  position: absolute;
  top: 39%;
  right: 45%;
}
.image-check2 {
  position: absolute;
  top: 58%;
  right: 45%;
}

.image-check3 {
  position: absolute;
  top: 77%;
  right: 45%;
}

span.underline-go-agile {
  border-bottom: 10px solid #fac710;
  width: 110px;
  height: 1px;
  margin-top: 4px;
  display: block;
  margin-left: 59rem;
}

.serve-agile {
  position: relative;
  right: 13%;
}

.gac {
  margin-left: 20px !important;
}

.card-agile {
  width: calc(350px + 1vw);
  height: 325px;
  border-radius: 10px;
  background-color: #fff;
  position: initial;
  z-index: 66;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 4px;
}

.carousel-container-agile {
  display: flex;
  gap: 20px;
}

.card-content-agile {
  font-weight: 300;
  font-size: 1.5em;
  width: 90%;
  padding: 0 15px;
}

.sect-desc {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.goagile-description-para p {
  font-weight: 300;
  font-size: 1.2em;
}

.why-agile-it {
  display: block;
  margin-top: 2rem;
}

.list-ideas-text {
  margin-left: 3rem;
}

.list-img {
  position: absolute;
  width: 2%;
  top: 63%;
}
.list-img1 {
  position: absolute;
  width: 2%;
  top: 77%;
}
.list-img2 {
  position: absolute;
  width: 2%;
  top: 87%;
}

.requestQuote {
  border: 1px solid black;
  text-align: center;
  width: 25rem;
  height: 2.5rem;
  margin: 0 auto;
  margin-top: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: black;
  color: white;
  font-size: 24px;
  border-radius: 50px;
  cursor: pointer;
}

.requestQuote:hover {
  transform: scale(1.1);
}

p.req {
  border: 2px solid #fac710;
  width: 50%;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 1rem;
  height: 4rem;
  font-size: 27px;
  border-radius: 6px;
}

.form-alignment {
  margin-top: 1rem;
  margin-left: 4.4rem;
  display: flex;
  flex-direction: column;
  gap: 1.3em;
}

.agile-coaching {
  border-color: 0px solid wheat;
  height: 2rem;
}

.short-input {
  border: 0px;
  height: 2rem;
  text-align: center;
  width: 54rem;
}

.short-input1 {
  background-color: white;
  border: 0px;
  height: 2rem;
  text-align: center;
  width: 54rem;
  color: black;
}

.short-input1::placeholder {
  color: black;
}

.short-input-last {
  border: 0px;
  height: 2rem;
  text-align: center;
  width: 54rem;
  margin-bottom: 2rem;
}

.two-last-cards {
  padding-bottom: 2rem;
  margin: 0 auto;
  gap: 4rem;
  display: flex;
}

button.go-back {
  cursor: pointer;
  background-color: #000;
  color: white;
  height: 3rem;
  width: 15rem;
}

button.go-back:hover {
  background-color: #fac710;
  transform: scale(1.08);
}

button.sss:hover {
  background-color: #fac710;
  transform: scale(1.08);
}

button.sss {
  cursor: pointer;
  background-color: #000;
  color: white;
  height: 3rem;
  width: 15rem;
}

p.start-a-project {
  font-size: 17px;
  font-weight: 650;
  margin-left: 4rem;
}

p.agile-coaching {
  border: 1px solid;
  background: white;
  width: 86%;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 auto;
}

button.close-button {
  position: absolute;
  margin-left: 15rem;
  margin-top: -18px;
  width: 3rem;
  height: 2rem;
}

.quote-form {
  background-color: #fbf6e4;
  position: relative;
  top: -150px;
}

.ring-agile {
  position: absolute;
  z-index: -4;
  left: 75%;
  margin-top: -21em;
}

html {
  overflow-x: hidden;
}
p.price-data11 {
  font-size: 18px;
  font-style: normal;
  font-weight: 550;
  text-align: left;
  margin-left: -7rem;
  color: #252430;
}
.num-color {
  position: absolute;
  top: 60%;
  border: 2px solid black;
  border-radius: 100%;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: black;
  color: white;
}

.num-color1 {
  position: absolute;
  top: 77%;
  border: 2px solid black;
  border-radius: 100%;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: black;
  color: white;
}
.num-color2 {
  position: absolute;
  top: 87%;
  border: 2px solid black;
  border-radius: 100%;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: black;
  color: white;
}

.price-footer-note {
  display: flex;
  background-color: #ffffff;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border: 1px solid #e6e9f5;
  width: 100%;
  max-width: 100%;
  border-top: 0px;
  justify-content: center;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  padding: 1rem;
}

.ga-it-content-right {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
}

.ga-it-content-right img {
  object-fit: contain;
  margin-top: -90px;  
  right: 38px;
  position: relative;
  top: 42px;
  width: 574px;
}

.ga-it-ring4 {
  z-index: -3;
  position: absolute;
  width: 28%;
  margin-left: 504px;
  margin-top: 13px;
} 

.ga-it-bean1 {
  z-index: -3;
  position: absolute;
  width: 34%;
  margin-left: 569px;
  margin-top: -49px;
}

@media only screen and (max-width: 550px) {
  .pricing-section{

  }
  .prices-container{
    flex-direction: column;
  }
  div.price-carrousel{
    width: 100%;
    overflow: hidden;
  }
  .ga-it-bean1,
  .ga-it-ring4 {
    display: none;
  }
  .carousel-container-agile{
    flex-direction: column;
    align-items: center;
  }
  .ga-it-content-right img {
    object-fit: contain;
    margin-top: -90px;
    right: 38px;
    position: relative;
    top: 42px;
    width: 204px;
}
.gagile-description-para p{
  width: 100%;
  margin-left: 0 !important;
}
}
@media only screen and (max-width: 920px) {
  span.header-bold {
    margin-left: 0rem;
  }
  .forty-off{
    width: 10rem;
  }
  .repair,span.start {
    margin-bottom: 2rem;
  }
  div.choose-plan-btn{
    height: auto;
  }
  .price-topic{
    width: 100%;
  }
  .price-topic1{
    align-items: center;
    width: 100%;
  }
  .price-topic2{
    align-items: center;
    width: 100%;
  }
  .price-topic3{
    align-items: center;
    width: 100%;
  }
  .price-topic4{
    align-items: center;
    width: 100%;
  }
  .sub-text{
    width: 90%;
  }
  .price-footer-note{
    padding: 1rem;
  }

  .second-text {
    margin-left: 0rem;
  }

  .slim-text.line {
    padding-bottom: -20px;
  }

  .switch-1 {
    top: 5rem;
    right: 8rem;
  }
}
