/* .footer-section {
    height: 35vh; 
    width: 100%;
    background-color: #fac710;
} */


.content-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 6rem;
    padding-top: 3rem;
    flex-direction: column;
}

.cookie-info{
    text-align: center;
}
.cookie-info>p{
    color: black;
    font-size: 17px;
    font-weight:600;
    line-height: 23px;
}

.select-option{
    display: flex;
    margin-top: 2rem;
    flex-direction: row;
    gap: 3rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.checkbox-label{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
}
.checkbox-wrapper>input{
    height: 2rem;
    width: 2rem;
}
input:checked{
    background-color: black;
    color: #fff;
}
.checkbox-label>p{
    color: black;
    font-size: 17px;
    font-weight:600;
    line-height: 23px;
}
.checkbox-label > input:checked{
    background-color: black; /* Background color when the checkbox is checked */
    border-color: white; 
}




@media(max-width:480px){
    .footer-section{
        height: 70vh;
    }
    .content-container{
        margin: 3rem 1rem;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .cookie-info{
        text-align: start;
    }
    .checkbox-label{
        display: flex;
        flex-direction: row;
    }
    .select-option{
        margin: 2rem 1rem;
        display: flex;
    }
    .cookie-info>p{
        font-size: 15px;
    }
    .checkbox-label>p{
        font-size: 15px;
    }
    .checkbox-wrapper>input{
        width: 1rem;
        height: 1rem;
    }

}

@media(min-width:481px) and (max-width:767px){
    .footer-section{
        height: 70vh;

    }
    .content-container{
        margin: 3rem 1rem;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 2rem;
    }
    .cookie-info{
        text-align: start;
    }
    .checkbox-label{
        display: flex;
        flex-direction: row;
    }
    .select-option{
        margin: 2rem 1rem;
        display: flex;
    }
    .cookie-info>p{
        font-size: 15px;
    }
    .checkbox-label>p{
        font-size: 15px;
    }
    .checkbox-wrapper>input{
        width: 1rem;
        height: 1rem;
    }
}

@media(min-width:768px) and (max-width:991px){
    .footer-section{
        height: 70vh;
        padding-bottom: 2rem;
    }
    .content-container{
        margin: 3rem 1rem;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 2rem;
    }
    .cookie-info{
        text-align: start;
    }
    .checkbox-label{
        display: flex;
        flex-direction: row;
    }
    .select-option{
        margin: 2rem 1rem;
        display: flex;
    }
    .cookie-info>p{
        font-size: 15px;
    }
    .checkbox-label>p{
        font-size: 15px;
    }
    .checkbox-wrapper>input{
        width: 1rem;
        height: 1rem;
    }
}

@media(min-width:992px) and (max-width:1199px){
    .footer-section{
        height: 70vh;
        padding-bottom: 2rem;
    }
    .content-container{
        margin: 3rem 1rem;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 2rem;
    }
    .cookie-info{
        text-align: start;
    }
    .checkbox-label{
        display: flex;
        flex-direction: row;
    }
    .select-option{
        margin: 2rem 1rem;
        display: flex;
    }
    .cookie-info>p{
        font-size: 15px;
    }
    .checkbox-label>p{
        font-size: 15px;
    }
    .checkbox-wrapper>input{
        width: 1rem;
        height: 1rem;
    }
}


















.cookies {
    background-color: #fac710;
    bottom: 0;
    display: flex;
    flex-direction: row;
    padding: 2% 10px;
    position: absolute;
    position: fixed;
    transition: 1s;
    width: 100%;
    z-index: 99;
    }

.cookies .col-lef {
    display: flex;
    flex-direction: column;
    /* width: 70%; */
    gap: 20px;
}

.cookies .col-lef .detail {
    font-size: 22px;
    font-weight: 400;
    padding: 10px 8%;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;

}
    .cookies .col-lef .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10%;
    width: 77%;
    }
    .cookies .col-lef .buttons .li {
    display: flex;
    flex-direction: column;
    }

.cookies .col-lef .buttons .li label {
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}
    .cookies .col-lef .buttons .li input {
    accent-color: #111;
    height: 20px;
    margin: 10px;
    padding: 10px;
    width: 20px;
    }
.cookies .col-ryt {
    align-items: end;
    display: flex;
    justify-content: flex-start;
    /* width: 30%; */

}

.cookies .col-ryt p {
    width: 280px;
    height: 47px;
    border: 2px solid #111;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    font-weight: 500;
    padding: 16px 0;
    text-decoration: none;
    transition: 0.5s;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;

}
    .cookies .col-ryt a:hover {
    background-color: #111;
    color: #fff;
    }

.hide {
    display: none;
}

/* MOBILE RESPONSIVE SECTION */

@media only screen and (max-width: 920px) {
    .cookies {
        width: 85%;
        padding: 20px;
        flex-direction: column;
    }

    .cookies .col-lef .detail {
        font-size: 16px;
        line-height: unset;
        padding: unset;
    }

    .cookies .col-lef .buttons {
        flex-direction: column;
        /* gap: 15px; */
    }

    .cookies .col-lef .buttons .li {
        justify-content: inherit;
        flex-direction: row;
    }

    .cookies .col-lef .buttons .li label {
        font-size: 16px;
    }

    .cookies .col-ryt {
        margin-top: 15px;
    }
}