/*------------------------------------- Global Styles -----------------------------------*/

.section-heading > div {
  font-size: calc(32px + 1vw);
  
  line-height: calc(40px + 1vw);
  letter-spacing: -0.87px;
  color: #111;
  text-align: left;
  font-weight: 700;

}
.section-heading span {
  font-weight: 300;
}
.section-heading .underline {
  border-bottom: 10px solid #fac710;
  width: 113px;
  height: 6px;
  margin-top: 5px;
 
  margin-left: 45rem;
}
.section-heading p {
  font-size: 19px;
  text-align: left;
  line-height: 30px;
  font-weight: 300;
  float: left;
  padding: 15px 3rem;
  
}

/*--------- responsiveness  ----------*/

@media (max-width: 920px) {
  .wga-content-wrapper {
    padding-bottom: 2rem;
  }

  .wga-content-wrapper .section-heading > div {
    max-width: 100% !important;
    font-size: 30px !important;
    font-weight: 400 !important;

  }
  .image-check{
    display: none;
  }
  .image-check2{
    display: none;
  }
  .image-check3{
    display: none;
  }
  .section-heading p {
    width: 100%;
    max-width: 100% !important;
    padding: 15px 0px;
  }
  .section-heading .underline {
    float: unset !important;
  }
}

/*---------------------------- END Global Styles ---------------------------------*/

/* Hero Section Styling */

.home-wrapper .hero-content {
  min-height: 80vh;
  width: 100%;
  /* margin: 0 auto; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-bottom: 60px; */
  padding-left: 3rem;
  padding-right: 3rem;
}

.home-wrapper .content-left {
  position: relative;
  width: 100%;
  max-width: 600px;
  padding-top: 2rem;
}

.home-wrapper .content-right {
  margin-right: -50px !important;
}

.home-wrapper .content-right img {
  width: 100%;
  max-width: 700px;
}

.home-wrapper .content-left > div {
  font-size: calc(42px + 1vw);
  line-height: 100%;
  letter-spacing: -0.87px;
  color: #111;
  text-align: left;
  font-weight: 700;
}

.underline {
  display: block;
  border-bottom: 11px solid #fac710;
  width: 230px;
  height: 11px;
  margin-top: 5px;
  margin-left: 5px;
}

.home-wrapper .content-left .top-details {
  padding-top: 20px;
  width: 540px;
}

.home-wrapper .content-left .heading span {
  font-weight: 300;
}

.home-wrapper .content-left p {
  font-size: calc(12px + 1vw);
  text-align: left;
  line-height: 30px;
  font-weight: 300;
}

.home-wrapper .hero-content-right {
  /* width: 50%;
  display: flex;
  position: absolute;
  right: -82px;
  top: 145px; */

  width: 50%;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 145px;
    justify-content: center;
}

.home-wrapper .hero-content-right img:first-child {
  position: absolute;
  height: 38rem;
}

.home-wrapper .hero-content-right img:last-child {
  position: relative;
  top: 163px;
  height: 26rem;
}

/*--------- responsiveness  ----------*/

@media (max-width: 920px) {
  .home-wrapper .hero-content {
    flex-direction: column-reverse;
    padding: 20px 20px;
    margin-bottom: 9rem !important;
  }

  .home-wrapper .content-left {
    max-width: 100%;
    /* padding-top: 20px; */
    top: 15rem !important;
    margin-bottom: 5rem;
  }

  .home-wrapper .content-left p{
    font-weight: 400;
  }

  .home-wrapper .content-left .heading {
    font-size: 40px;
  }

  .home-wrapper .content-left .top-details {
    width: unset;
  }

  .home-wrapper .content-right {
    max-width: 100%;
    margin-right: 0 !important;
  }

  .home-wrapper .hero-content-right{
    margin: 0 auto;
    width: 100%;
    top: 136px;
    justify-content: flex-end;
    right: unset;
  }

  .home-wrapper .hero-content-right img:first-child{
    margin: unset;
    height: unset;
    top: -45px;  
  }

  .home-wrapper .hero-content-right img:last-child {
    top: unset;
    height: unset;
    margin: unset;

  }

}

/*--------- service-content-wrapper styles  ----------*/
.service-content-wrapper {
  width: 96%;
  margin: 0 auto;
  position: relative;
  padding: 40px 0 40px 0;
}

.service-content-wrapper .service-container {
  background-color: #fbf6e4;
  border-top-right-radius: 400px;
  position: relative;
  z-index: 33;
  padding: 3rem 3rem;
  display: flex;
  flex-direction: column;
}
/*--------- Set underline properly  ----------*/
.service-content-wrapper .section-heading > div {
  max-width: 390px !important;
}

.service-content-wrapper .section-heading {
  padding-top: 20px;
}

.service-content-wrapper .section-heading p {
  font-weight: 400;
  color: #111111;
}

.service-content-wrapper .ring-img {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 11;
  margin-bottom: -50px;
}

.service-content-wrapper .section-content {
  display: flex;
  padding: 20px 0;
  right: 0;
  bottom: 0;
  margin-top: -3rem;
}

.service-content-wrapper .section-content .carousel-container {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-right: -14rem;

}

.service-content-wrapper .section-content .ctrls-indicator {
 
}




.service-content-wrapper .section-content .carousel-container .card {

  align-items: center;
  display: flex;
  width: 318px;
  height: 276px;
  border-radius: 10px;
  background-color: var(--white-background);
  box-shadow: 0px 0px 9px 2px #0000000D;


}

.service-content-wrapper .carousel-container .card {
  padding: 10px;
}

.service-content-wrapper .carousel-container .card-content {
  padding: 14px;
}

.service-content-wrapper .carousel-container .card .img-box {
  padding: 5px;
  border: 2px solid #383838;
  max-width: 55px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-content-wrapper .card h2 {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.055em;
  text-align: left;
}

.service-content-wrapper .card p {
  font-size: 15px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: left;

}

/*--------- responsiveness  ----------*/

@media (max-width: 920px) {
  .service-content-wrapper {
    flex-direction: column;
    width: 99%;
    margin: initial;
    padding: 15px 0px;
  }

  .service-content-wrapper .section-heading {
    width: 100% !important;
    max-width: unset !important;
  }
  .service-content-wrapper .section-heading > div {
    width: 200% !important;
    max-width: unset !important;
  }

  .service-content-wrapper .ring-img {
    margin-bottom: -50px;
  }

  .service-content-wrapper .section-content {
    justify-content: center;
    flex-direction: column;
    margin-right: 0 !important;
  }
  .service-content-wrapper .service-container {
    border-top-right-radius: 100px;
    /* padding: 2rem 20px; */
  }
  .service-content-wrapper .section-content .carousel-container {
    justify-content: center;
    flex-direction: column;
    gap: 35px;
  }
}
/*--------- wga why-go-agile styling  ----------*/

.wga-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-top: 5rem;
  padding-bottom: 4rem;
}

.wga-content-wrapper .wga-container {
  display: flex;
  align-items: center;
  width: 95%;
  padding: 0 3rem;
  gap: 3rem;
  justify-content: space-between;
}

.wga-content-wrapper .wga-content-left {
  max-height: 550px;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wga-content-wrapper .wga-content-right {
  max-width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-right: 3rem;
  padding-top: 1rem;
}

img.ring3{
  z-index: -3;
  position: absolute;
  width: 414px;
  margin-left: 3px;
  margin-top: 80px;

  height: 454px;
}

img.bean{
  z-index: -3;
  position: absolute;
  width: 476px;
  margin-left: -19px;
  margin-top: 40px;
  height: 717.5px;
}

img.ring4{
  z-index: -3;
    position: absolute;
    width: 28%;
    margin-left: 541px;
    margin-top: -54px;
}

img.bean1{
  z-index: -3;
    position: absolute;
    width: 34%;
    margin-left: 569px;
    margin-top: -15px;
}


.wga-content-wrapper .wga-content-left img {
  width: 763px;
  object-fit: contain;
  margin-top: 55px;
  margin-left: -62px;
  height: 730px;
}

.wga-content-wrapper .section-heading {
  padding-bottom: 15px;
}

.wga-content-wrapper .section-heading div {
  font-size: 60px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.01em;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.wga-content-wrapper .section-heading .underline {
  border-bottom: 8px solid #fac710;
  width: 81px;
  height: 6px;
  margin: unset;
}

.wga-content-wrapper .wga-description-para {
  width: 495px;
  height: 555px;
  font-size: 25px;
  font-weight: 300;
  line-height: 37px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 50px;

}

/*--------- responsiveness  ----------*/

@media (max-width: 920px) {
  .wga-content-wrapper .wga-container {
    flex-direction: column;
    padding: 20px 1rem;
    gap: unset;
  }

  .wga-content-wrapper .wga-content-left div {
    height: 418px;
  }

  .wga-content-wrapper .wga-content-left img {
    margin: 0 !important;
    position: relative;
    left: 20px;
    width: 341px;
    height: inherit;
  }

  
}
@media (max-width: 620px) {
  .wga-content-wrapper .wga-content-right {
    padding-right: 0rem;
  }
  .num-color{
    top: 46% !important;
  }
  .num-color1{
    top: 54% !important;
  }
  .num-color2{
    top: 71% !important;
  }

}

/*............................ recent-engagements styling .........................................................................*/

.re-content-wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 40px 0 40px 0;
}

.re-content-wrapper .re-container {
  background-color: #fbf6e4;
  border-top-left-radius: 400px;
  margin-left: 80px;
  position: relative;
  /* z-index: 33; */
  padding: 3rem 3rem;
  display: flex;
  flex-direction: column;
}

/* Set underline properly */
.re-content-wrapper .section-heading > div {
  text-align: right;
  max-width: 100% !important;

  font-size: 48px;
  font-weight: 700;
  letter-spacing: -0.01em;

}

.re-content-wrapper .section-heading {
  padding: 20px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.re-content-wrapper .section-heading p {
  font-weight: 400;
  color: #111111;
}

.re-container .section-heading .underline {
  width: 150px;
  margin-left: unset;
}

.re-content-wrapper .ring-img {
  position: absolute;
  z-index: 11;
  margin-bottom: -50px;
  margin-left: -150px;
  bottom: 50px;
  left: 148px;
}

.re-content-wrapper .section-content {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  right: 0;
  bottom: 0;
  margin-right: 5rem;
  width: 100%;
}

.re-content-wrapper .section-content .carousel-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 45px;
  right: 0;
}

.re-content-wrapper .section-content .carousel-container .card {
  position: relative;
  /* width: calc(250px + 1vw); */
  height: 299px;
  border-radius: 10px;
  background-color: #fff;
  position: initial;
  z-index: 66;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 4px;

  width: 326px;
  box-sizing: border-box;

}

.re-content-wrapper .carousel-container .card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* background: #331b3b; */
}

.re-content-wrapper .carousel-container .card .img-box {
  padding: 5px;
  /* background: #111; */
  width: 100%;
  max-width: 250px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.re-content-wrapper .carousel-container .card .img-box img:nth-child(1) {
  width: 90%;
}

.re-content-wrapper .carousel-container .card .img-box img:nth-child(2) {
  width: 30%;
}

.re-content-wrapper .carousel-container .card .img-box img:nth-child(3) {
  width: 30%;
}

.re-content-wrapper .card p {
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  padding: 6px 15px;
  background: #fac710;
  border: 1px solid #111;
  border-radius: 100px;
}

/*--------- responsiveness  ----------*/

@media (max-width: 920px) {
  .re-content-wrapper {
    flex-direction: column;
    padding-right: 0px;
  }
  .wga-content-wrapper{
    padding-top: 0 !important;
  }
  .re-content-wrapper .re-container {
    border-top-left-radius: 100px;
    margin-left: 10px !important;
    padding: 20px 20px;
  }
  .re-content-wrapper .section-heading {
    width: 100% !important;
    max-width: unset !important;
  }
  .re-content-wrapper .section-heading > div {
    width: 100% !important;
    max-width: unset !important;
  }

  .re-content-wrapper .ring-img {
    margin-bottom: -50px;
    margin-right: -160px;
  }

  .re-content-wrapper .section-content {
    justify-content: center;
    flex-direction: column;
    margin-right: 0 !important;
  }
  

  .re-content-wrapper .section-content .carousel-container {
    justify-content: center;
    flex-direction: column;
    gap: 35px;
  }
}

/*....................................... why XAS? styling ..................................................................*/

.xas-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-top: 4rem;
}

.xas-content-wrapper .xas-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 3rem;
  gap: 3rem;
  justify-content: space-between;
}

.xas-content-wrapper .xas-content-left {
  max-width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  /* background-color: #fbf6e4; */
}

.xas-content-wrapper .xas-content-right {
  /* max-height: 500px;
  max-width: 660px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  /* background-color: aquamarine; */
}

.xas-content-wrapper .xas-content-right img {
  width: 100%;
  object-fit: contain;
  margin-top: -90px;
  /* margin-right: -160px; */
}

.xas-content-wrapper .section-heading {
  padding-bottom: 15px;
}

.xas-content-wrapper .section-heading > div {
  /* text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */

  font-size: 48px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: -0.01em;
  text-align: left;

}

.xas-content-wrapper .section-heading .underline {
  border-bottom: 8px solid #fac710;
  width: 80px;
  height: 6px;
  margin-left: 116px;
}

.xas-content-wrapper .xas-description-para {
  width: 423px;
  height: auto;

  font-size: 25px;
  font-weight: 300;
  line-height: 37px;
  letter-spacing: 0.02em;
  text-align: left;
}

.xas-content-left .xas-description-para p span {
  font-weight: 400;
}

.xas-content-left .xas-description-para p span span {
  font-weight: 700;
}

.xas-dots {
  width: 604px;
  height: 598px;
  border: 2px dashed var(--navbar-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.xas-cert-desc {
  /* width: 322px;
  height: 308px; */
  width: 50%;
  height: 50%;
  background-color: var(--org-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 5;
}

.xas-cert-desc:hover {
  background-color: var(--navbar-color);
  color: var(--white-background);
  cursor: pointer;
  transition: .8s;
}

.xas-cert-desc p {
  font-size: 35px;
  font-weight: 800;
  line-height: 51px;
  letter-spacing: 0.02em;
  text-align: center;
  width: 232px;
  height: 147px;
}

.xas-certs-cards-container {
  height: 100%;
  position: absolute;
  animation: XasCertContainerSpin 12s linear 0s infinite normal;
}

.xas-cert-card {
  width: 179px;
  height: 155px;
  background-color: var(--navbar-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  animation: XasCertImgSpin 12s linear 0s infinite normal;
}

.xas-cert-card img {
  width: 102px;
  height: 115px;
  margin: unset !important;
  /* animation: XasCertImgSpin 10s linear 0s infinite normal; */
}

.xas-cert-card:first-child {
  top: 125px;
  right: -198px;
}

.xas-cert-card:nth-child(2) {
  top: -11px;
  left: -217px;
}

.xas-cert-card:nth-child(3) {
  bottom: -148px;
  right: 268px;
}

.xas-cert-card:nth-child(4){
  top: -40px;
  left: 279px;
}

@keyframes XasCertContainerSpin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@keyframes XasCertImgSpin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(-360deg);}
}


/*--------- responsiveness  ----------*/

@media (max-width: 920px) {
 
  .xas-content-wrapper .xas-container {
    flex-direction: column;
  }
  .xas-content-wrapper .xas-content-left {
    max-width: 100%;
    padding: unset;
  }

  .xas-content-wrapper .xas-description-para {
    font-size: 16px;
    font-weight: 400;
    width: unset;
  }
  
  .xas-content-wrapper .xas-content-right {
    max-width: 100%;
  }

  .xas-dots {
    width: 200px;
    height: 190px;
  }

  .xas-certs-cards-container, .xas-cert-card img {
    height: unset;
  }

  .xas-cert-card {
    width: 55px;
    height: 50px;
  }

  .xas-cert-card:first-child {
    top: 17px;
    right: -54px;
  }

  .xas-cert-card:nth-child(2) {
    top: -21px;
    left: -74px;
  }

  .xas-cert-card:nth-child(3) {
    bottom: -51px;
    right: 84px;
  }

.xas-cert-card:nth-child(4) {
  top: -9px;
  left: 90px;
}

  .xas-cert-desc {
    width: 61%;
    height: 61%;
  }

  .xas-cert-desc p {
    font-size: 16px;
    font-weight: 400;
    line-height: unset;
    letter-spacing: unset;
    height: unset;
  }

  .xas-content-wrapper .section-heading {
    display: flex;
    justify-content: center;
  }
  
  .xas-content-wrapper .xas-content-right img {
    margin-right: 0 !important;
  }
  
}

/*......................get-value-faster-content-wrapper styles ------------------------------------------------------------------*/

.gvf-content-wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 40px 0 40px 0;
}

.gvf-content-wrapper .gvf-container {
  background-color: #fbf6e4;
  border-top-right-radius: 400px;
  margin-right: 80px;
  position: relative;
  z-index: 33;
  padding: 3rem 3rem;
  display: flex;
  flex-direction: column;
}

/* Set underline properly */
.gvf-content-wrapper .section-heading > div {
  /* max-width: 450px !important; */

  width: 767px;
  height: 106px;
  font-size: 48px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: -0.01em;
  text-align: left;

}

.gvf-content-wrapper .section-heading > div > span {
  font-weight: 300;
}

.gvf-content-wrapper .section-heading div .underline {
  margin-left: unset;
  width: 192px;
} 

.gvf-content-wrapper .section-heading {
  padding: 20px 0;
}

.gvf-content-wrapper .section-heading p {
  font-weight: 400;
  color: #111111;
}

.gvf-content-wrapper .ring-img {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 11;
  margin-bottom: -50px;
}

.gvf-content-wrapper .section-content {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
  right: 0;
  bottom: 0;
  margin-right: 5rem;
  width: 100%;
}

.gvf-content-wrapper .section-content .carousel-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 35px;
  right: 0;
  margin-right: -20px;
}

.gvf-content-wrapper .section-content .ctrls-indicator {
  position: absolute;
    padding-right: 12rem;
    top: 6rem;
}

.gvf-content-wrapper .section-content .carousel-container .card {
  width: 235px;
    height: 178px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 4px;
}

.gvf-content-wrapper .carousel-container .card {
  padding: 10px;
}

.gvf-content-wrapper .carousel-container .card-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gvf-content-wrapper .carousel-container .card .img-box {
  padding: 5px;
  border: 2px solid #383838;
  max-width: 55px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gvf-content-wrapper .card h2 {
  text-transform: uppercase;
  font-size: 38px;
  line-height: 21.97px;
  color: #331b3b;
  padding-top: 15px;
  text-align: center;
}

.gvf-content-wrapper .card p {
  font-size: 22px;
  font-weight: 370;
  line-height: 20px;
  text-align: center;
}

/* ........................testimonials-content-wrapper styles ------------------------------------------------------------------*/

.testimonials-content-wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 30px 0 40px 0;
}

.testimonials-content-wrapper .testimonials-container {
  position: relative;
  z-index: 33;
  padding: 2rem 6rem;
  display: flex;
  flex-direction: column;
}

/* Set underline properly */
.testimonials-content-wrapper .section-heading > div {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  text-align: center !important;
  flex-direction: column;
}

.testimonials-content-wrapper .section-heading {
  padding-bottom: 30px;
}

.testimonials-content-wrapper .section-heading .underline {
  float: unset;
  margin: 0 auto;
}

.testimonials-content-wrapper .section-heading p {
  font-weight: 400;
  color: #111111;
}

.testimonials-content-wrapper .section-content {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  right: 0;
  bottom: 0;
  margin-right: 5rem;
  width: 100%;
  margin: 0 auto;
}

.testimonials-content-wrapper .section-content .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 70px;
  right: 0;
}

.testimonials-content-wrapper .section-content .carousel-container .card {
  width: 19rem;
  height: 300px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 7px 1px #00000014;
}

.testimonials-content-wrapper .carousel-container .card {
  padding: 20px;
}

.testimonials-content-wrapper .carousel-container .card-content {
  padding: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.testimonials-content-wrapper .carousel-container .card .img-box {
  padding: 5px;
  max-width: 55px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonials-content-wrapper .card h2 {
  font-size: 16px;
  line-height: 23px;
  color: #331b3b;
  font-weight: 600;
}

.testimonials-content-wrapper .card p {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #331B3B;
}

.testimonials-content-wrapper .testimo-user {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: #331b3b;
  font-weight: 700;
  line-height: 0;
  padding-top: 30px;
  justify-content: center;
}

.testimonials-content-wrapper .testimo-user span {
  font-size: 30px;
  font-weight: 800;
  color: #fac710;
  line-height: 0;
  padding-right: 15px;
}

.three-people-using-laptop-img {
  margin-top: -3rem;
  height: 30rem;
  width: 37rem;
  margin-right: -4rem;
}

p span.what-we-do-bold {
  font-weight: bold;
  margin-top: 2rem;
  font-size: 42px;
  position: relative;
  top: -4rem;
  margin-left: -3rem;
}

span.business-underlinef, span.business-yellow {
  /* margin-bottom: 24px; */
  font-size: 44px;
  position: relative;
  top: -57px;
  
  /* text-decoration: underline rgb(255, 207, 51);
  text-decoration-thickness: 0.2em;  */
}

span.business-underlinef{
  text-decoration: underline rgb(255, 207, 51);
  text-decoration-thickness: 10px; 
  margin-left: -45px;
}

/* span.by-our-experts {
  margin-right: 15rem;
} */

.spacex{
  padding-bottom: 2.2rem;
  font-weight: 700;
}

span.yes-yellow{
  color: #fac710;
  font-weight: 540;
}

/* span.agile-ways{
  font-weight: 350;
  font-size: 30px;
  margin-left: 25px;
  line-height: 3rem;
} */

.card-design {
  display: flex;
  flex-direction: row;
  gap: 41px;
  contain: content;
}
.ad-value-slide-container {
  display: flex;
  flex-direction: row;
}

.added-value-slider {
  contain: content;
  padding: 30px 0px;
  display: flex;
  flex-direction: row;
  width: 101%;
}

.slider {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out;
}

.slider.activee, .next-slide, .previous-slide {
  display: flex;
  gap: 22px;
}

.card:hover, .slide-card:hover {
  /* background-color: var(--org-primary-color);
  color: var(--navbar-color); */
  transform: scale(.9);
  transition: .8s;
  cursor: pointer;
}

.slide-card {
  width: 369px;
  height: 273px;
  background-color: var(--white-background);
  box-shadow: 0px 0px 9px 2px #0000000D;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* align-items: center; */
  /* z-index: 35; */
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}

.slide-title {
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.055em;
  text-align: left;
  width: 234px;
  height: 71px;
}

.slide-img {
  width: 61px;
  height: 61px;
  border-radius: 10px;
  border: 2px solid var(--navbar-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;

}

.slide-card .slide-img img {
  width: 42px;
}

.slide-desc {
  width: 235px;
  height: 99px;
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.02em;
  text-align: left;
}

.next-slide {
  transform: translateX(100%);
}

.previous-slide {
  transform: translateX(-100%);
}

.carousel-buttons {
  position: relative;
  margin-top: -1rem;
  margin-left: 47rem;
  top: -5rem;
  justify-content: center;
  display: flex;
}

.carousel-buttons button {
  height: 35px;
  width: 35px;
  border: none;
  background-color: transparent;
  font-size: 24px;
  border-radius: 50%;
  border: 2px solid var(--navbar-color);
  margin-left: 10px;
  text-align: center;
  padding: 10px 10px;
  padding-bottom: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  cursor: pointer;
}

.carousel-buttons button:active {
  transition: .9s;
  transform: scale(.7);
}

.check-business-readiness {
  text-align: center;
  font-size: 22px;
  font-weight: 650;
  border: 4px solid black;
  border-radius: 1rem;
  width: 18rem;
  margin-left: 8rem;
  margin-top: -3rem;
}

.explore-awow {
  display: flex;
  align-items: center;
  width: 450px;
  height: 82px;
}

.explore-awow button {
  /* color: white;
  background-color: black;
  text-align: center;
  font-size: 22px;
  font-weight: 650;
  border: 4px solid black;
  border-radius: 1rem;
  width: 19rem;
  margin-left: 49rem;
  margin-top: -3rem; */

  width: 450px;
  height: 82px;
  /* top: 2251px;
  left: 817px; */
  border-radius: 42.06px;
  border: none;
  background-color: var(--navbar-color);
  color: var(--white-background);

  font-size: 23px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.01em;
  text-align: left;
  padding: 0 16px;
  transition: .5s;
}

.explore-awow button:hover {
  /* background-color: var(--org-primary-color);
  color: var(--navbar-color); */
  transition: .6s;
  transform: scale(.85);
  background: var(--org-primary-color);
  color: var(--navbar-color);
  cursor: pointer;
  
}

.check-leadership-style {
  /* color: white; */
  /* background-color: black; */
  text-align: center;
  font-size: 22px;
  font-weight: 650;
  border: 4px solid black;
  border-radius: 1rem;
  width: 19rem;
  margin-left: 8rem;
  margin-top: 0rem;
}


.find-out-more {
  display: flex;
  justify-content: space-evenly;
  height: 290px;
  align-items: center;
  margin-bottom: 20px;
}


.find-out-more h3, .find-out-more h3 span {
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.01em;
  text-align: left;
  
}

.find-out-more h3 {
  display: flex;
  flex-direction: column;
}

.find-out-more h3 span {
  font-weight: 300;
}

.last-cta {
  /* margin-right: -5rem; */
  display: flex;
  gap: 1rem;
  /* margin-bottom: 3rem;
  right: 28rem;
  position: absolute;
  margin-top: -5rem; */
  justify-content: center;
  align-items: center;
}

.talk-to-us {
  background-color: black;
  color: white;
  border: 4px solid;
  width: 13rem;
  text-align: center;
  height: 3rem;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 20px;
  border-radius: 2rem;
  border: none;
  cursor: pointer;
  transition: 1s;
}

.talk-to-us:hover {
  background-color: var(--org-primary-color);
  color: var(--navbar-color);
  transform: scale(.9);
  transition: .8s;
}

.check-agile-mindset{
  border: 4px solid;
  width: 13rem;
  text-align: center;
  height: 2.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 20px;
  border-radius: 2rem;
}

/*......................key-facts-content-wrapper styles ------------------------------------------------------------------*/

.kf-content-wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 40px 0 40px 0;
}

.kf-content-wrapper .kf-container {
  background-color: #fbf6e4;
  border-top-left-radius: 400px;
  margin-left: 80px;
  position: relative;
  z-index: 33;
  padding: 3rem 3rem;
  display: flex;
  flex-direction: column;
}

/* Set underline properly */
.kf-content-wrapper .section-heading > div {
  /* max-width: 450px !important; */
  color: var(--navbar-color);
  width: 767px;
  height: 106px;
  font-size: 48px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: -0.01em;
  text-align: right;
}

.kf-content-wrapper .section-heading > div > span {
  font-weight: 300;
}

.kf-content-wrapper .section-heading div .underline {
  margin-left: unset;
  width: 192px;
} 

.kf-content-wrapper .section-heading {
  padding: 20px 0;
  display: flex;
  justify-content: end;
}

.kf-content-wrapper .section-heading p {
  font-weight: 400;
  color: #111111;
}

.kf-content-wrapper .ring-img {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  margin-bottom: -50px;
}

.kf-content-wrapper .section-content {
  /* display: flex;
  justify-content: flex-end; */
  padding: 20px 0;
  right: 0;
  bottom: 0;
  margin-right: 5rem;
  width: 100%;
}

.kf-content-wrapper .section-content .carousel-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 35px;
  right: 0;
  margin-right: -20px;
}
.kf-content-wrapper .section-content .carousel-container.mobile-view {
  flex-direction: column !important;
  display: none;
}

@media  (max-width:600px) {
  .kf-content-wrapper .section-content .carousel-container.mobile-view {
    display: flex;
  }
  .kf-content-wrapper .section-content .carousel-container {
    display: none;
  }
}

.kf-content-wrapper .section-content .ctrls-indicator {
  position: absolute;
  padding-right: 12rem;
  top: 6rem;
}

.kf-content-wrapper .section-content .carousel-container .card {
  width: 266px;
  height: 215px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 4px;
  box-sizing: border-box;
}

.kf-content-wrapper .carousel-container .card {
  padding: 10px;
}

.kf-content-wrapper .carousel-container .card-content {
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.kf-content-wrapper .carousel-container .card .img-box {
  padding: 5px;
  border: 2px solid #383838;
  max-width: 55px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kf-content-wrapper .card h2 {
  font-size: 45px;
  font-weight: 800;
  line-height: 62px;
  letter-spacing: 0.055em;
  color: var(--navbar-color);
  text-transform: uppercase;
  padding-top: 25px;
  text-align: center;
  margin: 0;
}

.kf-content-wrapper .card p {
  font-size: 22px;
  font-weight: 370;
  line-height: 20px;
  text-align: center;
}

.swiper {
  position: inherit;
}

.swiper-button-prev, .swiper-button-next {
  /* display: none; */
  height: 35px;
  width: 35px;
  border: none;
  background-color: transparent;
  font-size: 24px;
  border-radius: 50%;
  border: 2px solid var(--navbar-color);
  margin-left: 10px;
  /* text-align: center;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center; */
  transition: 1s;
  /* cursor: pointer; */
  color: var(--navbar-color);
  /* box-sizing: border-box; */
  top: 145px;
  left: 200px;
}

.swiper-button-next {
  right: unset;
  left: 250px;
}

.swiper-button-prev:active, .swiper-button-next:active {
  transform: scale(.7);
  transition: .9s;
}

.swiper-button-prev::after {
  content: '<';
  font-size: 24px;
}

.swiper-button-next::after {
  content: '>';
  font-size: 24px;
}


@media only screen and (max-width: 920px){

  .explore-awow, .explore-awow button {
    width: unset;
    height: 50px;
    justify-content: center;
  }

  .explore-awow button {
    font-size: 16px;
    font-weight: 400;
  }

  .text-container{
    /* position: absolute; */
    right: -1rem;
    top: 6rem;
  }

  img.three-people-using-laptop-img{
    /* width: 25rem;
    margin-left: -3rem; */
    margin-left: 3rem;
    top: 110px;
    position: absolute;
    width: 300px;
    height: 196px;
  }

  .check-business-readiness {
    width: 19rem;
    margin-left: 1rem;
  }

  .service-content-wrapper .section-content .carousel-container {
    margin-right: unset;
  }

  p span.what-we-do-bold {
    margin-left: 0rem;
  }

  span.business-underlinef {
    margin-left: 0rem;
    text-decoration: none;
  }

  span.business-yellow {
    text-decoration: underline var(--org-primary-color);
    text-decoration-thickness: 0.2em;
  }


  img.ring4 {
    display: none;
  }


 .section-heading .underline {
  float: unset !important;
  /* display: none; */
}

.wga-content-right .wga-description-para {
  font-size: 16px;
  font-weight: 400;
  width: unset;
  margin-bottom: 20px;
}

img.bean1{
  position: absolute;
  margin-top: 39rem;
  margin-left: 2rem;
  width: 16rem;
}

.underline {
  width: 9rem;
}

.card-design{
  /* margin-left: -3rem; */
  flex-direction: column;
}

.slide-card {
  width: 326px;
  padding: 10px;
  justify-content: space-evenly;
}

.gvf-content-wrapper .ring-img {
  display: none;
}

.testimonials-content-wrapper .testimonials-container {
  padding: 20px;
}

.kf-content-wrapper .kf-container {
  border-top-left-radius: 100px;
  margin-left: 10px;
  padding: 20px 10px;
}


.testimonials-content-wrapper .section-content .carousel-container {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.check-leadership-style {
  margin-left: 2rem;
}

span.by-our-experts {
  margin-right: 0rem;
}

.re-content-wrapper .section-heading > div {
  text-align: center;
  font-size: 40px;
  font-weight: 400;
}

img.bean {
  width: 82%;
  top: 126px;
  /* margin-left: 12px; */
  height: 271px;
}

img.ring3 {
  margin-top: 4rem;
  width: 15rem;
  height: 282px;
  left: 41px;
}


.last-cta {
  right: 6rem;
  width: 24rem;
  margin-top: 2rem;
}

/* span.agile-ways {
  display: flex;
  text-align: center;
  line-height: 2rem;
} */

.gvf-content-wrapper {
  top: 70px;
}

.gvf-content-wrapper .section-content .carousel-container {
display: flex;
flex-direction: column;
}

.gvf-content-wrapper .gvf-container {
  /* width: 14rem; */
  margin-right: 0px;
  padding: 10px;
  border-top-right-radius: 100px;
}

.gvf-content-wrapper .section-heading > div {
  width: unset;
  height: unset;
  line-height: unset;
  letter-spacing: unset;
  font-size: 40px;
  font-weight: 400;
}

.carousel-buttons {
  margin: unset;
  top: unset;
}

.slider {
  flex-direction: column;
}

.slide-title {
  font-size: 20px;
}

.slide-desc {
  font-size: 16px;
  height: unset;
}

.check-agile-mindset {
  line-height: 20px;
}

.kf-content-wrapper {
  margin-bottom: 110px;
}

.kf-content-wrapper .section-heading > div {
  font-size: 40px;
}

.swiper-wrapper {
  flex-direction: column;
  gap: 23px;
}

.kf-content-wrapper .section-content .carousel-container .card {
  width: 324px !important;
}

.kf-content-wrapper .card h2 {
  font-size: 30px;
}

.kf-content-wrapper .card p {
  font-size: 20px;
} 

.swiper-button-next, .swiper-button-prev {
  display: none;
}

.find-out-more {
  flex-direction: column;
  justify-content: center;
}

.find-out-more h3, .find-out-more h3 span {
  text-align: center;
}

.find-out-more h3 span {
  margin-top: 25px;
}

.talk-to-us {
  width: 283px;
}

}