.form-container{
    display: flex;
    flex-direction: column;
    min-width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.679); */
    backdrop-filter: blur(5px);
    align-items: center;
    position: absolute;
    top: 0;
    
    position: fixed;
    z-index: 11111;
    overflow-y: scroll;
    display: none;
}

.form-container-bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9998;
    overflow-y: auto;
}

.myform{
    display: flex;
    min-width: 70%;
    margin-top: 4%;
    background-color: #fbf6e4;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    z-index: 9999;
}

.form-head{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 2%;
    gap: 2%;
    position: relative;
}

.fheader{
    display: flex;
    width: 60%;
    border: 2px solid #fac710;
    justify-content: center;
    align-items: center;
    padding: 3% 0;
    font-size: 27px;
    border-radius: 6px;
        background: var(--navbar-color);
    color: var(--white-background);
}

/* .bContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    position: absolute;
    right: -30px;
} */

.bContainer button{
    width: 3rem;
    height: 2rem;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    background: transparent;
    font-size: 1.5rem;
    font-style: italic;
}

/* .form-start{
    display: flex;
    width: 90%;
    font-size: 17px;
    font-weight: 650;
    margin: 2% 0;    
} */

.form-start {
    font-size: 17px;
    font-weight: 650;
    margin: 1rem auto 1rem 7.4rem;
    border-bottom: 5px solid var(--org-primary-color);
    /* padding-left: 122px; */    
}

.form-inputs{
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 10px;
}

.form-inputs input, select, textarea{
    border: 0px;
    height: 2rem;
    text-align: center;
}

.form-inputs .uploadFile{
    display: flex;
    width: 100%;
    height: 2rem;
    background-color: white;
    align-items: center;
    justify-content: center;
    position: relative;
}

.form-inputs .uploadFile input{
    position: absolute;
    width: 100%;
    opacity: 0;
}

.form-inputs .uploadFile span{
    opacity: .6;
}

.form-inputs input:first-child{
    background-color: white;
}

.form-inputs input:last-child{
    background-color: white;
    display: none;
}

.form-inputs input::placeholder {
    color: black;
}

.form-btns{
    display: flex;
    width: 60%;
    justify-content: center;
    align-items: center;
    margin: 5% 0;
    justify-content: space-between;
}

.form-btns button{
    cursor: pointer;
    background-color: #000;
    color: white;
    height: 3rem;
    width: 15rem;
    border-radius: 42px;;
}

.form-btns button:hover{
    background-color: var(--org-primary-color);
    color: var(--navbar-color);
    transform: scale(1.08);
}

.display{
    display: flex;
}

@media(max-width: 400px) {
    
}