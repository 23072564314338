.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icons-btn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 4rem;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  /* box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 15%); */
  z-index: 9998
}
.styles-module_whatsappButton__tVits{
position: relative !important;
right: 0 !important;
bottom: 0 !important;
height: 45px !important;
width: 45px !important;
padding: 0 !important;
}
.styles-module_floatingWhatsapp__yWF5K{
width: auto !important;
margin-bottom: 8px !important;
}
.styles-module_floatingWhatsapp__yWF5K.email .styles-module_whatsappButton__tVits{
background-color: rgb(255, 72, 95);  
/* background-color:#fff; */
;
}
.styles-module_floatingWhatsapp__yWF5K.call .styles-module_whatsappButton__tVits{
background-color: #fac710;
;
}
.styles-module_whatsappButton__tVits:after{
display: none !important;
}
.styles-module_whatsappChatBox__MdQ5U{
bottom: 13rem !important;
right: 6rem !important;
}

.styles-module_chatFooter__TGv0P form .styles-module_buttonSend__kXjja svg{
height: auto !important;
width: auto !important;
}

.styles-module_chatBody__VBxQH .styles-module_messageTime__MIwoR{
display: none !important;
}

.whatsapp-icon {
  position: relative;
}

.whatsapp-icon:hover::before {
  content: "";
  position: absolute;
  bottom: 12.9px; /* Adjust as needed */
  right: 40.8px; /* Adjust as needed */
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent black;
  z-index: 999;
}

.whatsapp-icon:hover::after {
  content: "WhatsApp Us";
  position: absolute;
  bottom: 8px; /* Adjust as needed */
  left: -3.5rem;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 999;
  white-space: nowrap;
}

.call-icon {
  position: relative;
}

.call-icon:hover::before {
  content: "";
  position: absolute;
  bottom: 23.9px; /* Adjust as needed */
  right: 40.8px; /* Adjust as needed */
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent black;
  z-index: 999;
}

.call-icon:hover::after {
  content: "Talk to Us";
  position: absolute;
  bottom: 19px; /* Adjust as needed */
  left: -2.8rem;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 999;
  white-space: nowrap;
}


.email-icon {
  position: relative;
}

.email-icon:hover::after {
  content: "";
  position: absolute;
  bottom: 23.9px; /* Adjust as needed */
  right: 42px; /* Adjust as needed */
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent black;
  z-index: 999;
}

.email-icon:hover::before {
  content: "Email Us";
  position: absolute;
  bottom: 19px; /* Adjust as needed */
  right: 5.6rem; /* Adjust as needed */
  transform: translateX(50%);
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 999;
  white-space: nowrap;
}

.open-chaty {
  position: relative;
}

.open-chaty:hover::after {
  content: "Talk to Us";
  position: absolute;
  bottom: -20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 999;
}

.close-collapse {
  position: relative;
}


.close-collapse:hover::before {
  content: "Talk to Us";
  position: absolute;
  bottom: -20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 999;
}