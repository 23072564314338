.thanks-container{
    display: flex;
    position: absolute;
    top: 0;
    justify-content: center;
    padding-top: 5%;
    width: 100%;
    height: 100%;
    z-index: 111;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.074);
    display: none;
}

.thanks{
    display: flex;
    width: 30%;
    flex-direction: column;
    position: relative;
    height: 200px;
    padding: 10px 2%;
    border-radius: 5px;
    background-color: #fbf6e4;
}

.thanks button{
    position: absolute;
    right: 10px;
}

.show{
    display: flex;
}